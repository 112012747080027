<template>
  <div>
    <my-nav-bar
      title="选择客户"
      left-text="返回"
      left-arrow
    />
    <van-search v-model="listQuery.client_name" class="search-bar" placeholder="请输入客户名称" clearable @search="onSearch" />
    <div class="app-main-search-container">
      <van-list
        ref="list"
        v-model="loading"
        :finished="finished"
        @load="getList"
      >
        <van-empty v-if="showEmpty" description="暂无数据" />
        <van-cell v-for="client in list" :key="client.id" :title="client.name" is-link center @click="handleSelect(client)" />
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getClients } from '@/api/salesman-order'
export default {
  components: { myNavBar },
  data() {
    return {
      listQuery: {
        page: 0,
        limit: 20,
        client_name: '',
        store_id: this.$route.query.store_id
      },
      list: [],
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getClients(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    },
    reload() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.showEmpty = false
      this.$refs.list.check()
    },
    onSearch() {
      this.reload()
    },
    handleSelect(client) {
      this.$eventBus.$emit('select-client', client)
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-bar {
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
    z-index: 99;
  }
</style>
