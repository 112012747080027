import request from '@/utils/request'

export function getClients(params) {
  return request({
    method: 'get',
    url: 'salesman-order/client',
    params
  })
}

export function getGoodsList(params) {
  return request({
    method: 'get',
    url: 'salesman-order/goods',
    params
  })
}

export function submitOrder(data) {
  return request({
    method: 'post',
    url: 'salesman-order/order',
    data
  })
}
